<template>
  <div class="about-wrap">
    <div class="img-wrap">
      <div class="txt-w">
        <p>Consulting&Investment</p>
        <p>构建数字化财富管理新生态</p>
        <p>让投资触手可及</p>
      </div>
    </div>
    <div class="ct-x">
      <div class="ct-a">
        <img src="@/assets/images/myFavors/company.png">
        <div class="ctx-b">
          <div>
            <h3>公司介绍</h3>
            <p>博采众长 惠泽天下</p>
            <span>ABOUT US</span>
          </div>
          <p>众惠基金销售有限公司（以下简称“众惠基金”）成立于2016年，实缴资本5000万，是贵州省首家独立基金销售机构，同时也是中国证券投资基金业协会联席会员、贵州证券业协会会员。总部位于贵阳，分支机构分布于上海、深圳 、杭州、武汉四大城市，形成了金融一线城市的战略布局。</p>
        </div>
      </div>
    </div>
    <div class="ct-w">
      <div class="ct-a">
        <h3>一站式综合财富管理平台</h3>
        <p>
          众惠基金作为资产管理行业和投资人之间的桥梁，一直秉承着“价值发现、稳健投资”的经营理念，致力于将资深的行业经验与金融科技创新相结合，为投资者打造一站式综合财富管理平台。坚持专业、客观、审慎、严谨的基本原则，以金融科技创新为驱动，以客户利益为中心，为投资者提供全方位的资产配置解决方案与财富管理服务，助力广大投资者实现财富保值增值。
          
          
         
        </p>
        <div class="img-wrap1">
          <img src="@/assets/images/myFavors/p1.png">
          <img src="@/assets/images/myFavors/p2.png">
          <img src="@/assets/images/myFavors/p3.png">
          <img src="@/assets/images/myFavors/p4.png">
          <img src="@/assets/images/myFavors/p5.png">
          <img src="@/assets/images/myFavors/p6.png">
        </div>
      </div>
    </div>
    <div class="cul-wrap">
      <div class="txt-t">
        <span>COOPERATIVE CULTURE</span>
        <h3>企业文化</h3>
        <p>博采众长  惠泽天下</p>
      </div>
      <div class="cul-item item1">
        <h3>我们的使命</h3>
        <p>MISSION</p>
        <em>通过金融与科技的深度融合，促进行业数字化发展，让每个人都成为财富管理的受益者</em>
      </div>
      <img src="@/assets/images/myFavors/dline.png">
      <div class="cul-item item2">
        <h3>我们的愿景</h3>
        <p>VISION</p>
        <em>构建数字化财富管理新生态让投资触手可及</em>
      </div>
      <img src="@/assets/images/myFavors/dline.png">
      <div class="cul-item item3">
        <h3>企业价值观</h3>
        <p>VALUE</p>
        <em>以客户为导向<br>
          以专业为基准<br>
          以科技为动力</em>
      </div>
      <img src="@/assets/images/myFavors/dline.png">
      <div class="cul-item item4">
        <h3>经营理念</h3>
        <p>MANAGEMENT IDEA</p>
        <em>价值发现  稳健投资<br>
          博采众长 惠泽天下</em>
      </div>
    </div>
    <div class="aa-wrap">
      <h3>全面增强众惠基金数字化时代核心竞争力</h3>
      <div class="rect-w">
        <img src="@/assets/images/myFavors/rect.png">
        <div>
          <div class="top">
            <div
              class="item"
              :class="index === 1 ? 'active' : ''"
              @click="index = 1"
            >
              <img
                v-if="index === 1"
                src="@/assets/images/myFavors/ct3y.png"
              >
              <img
                v-else
                src="@/assets/images/myFavors/ct3n.png"
              >
              
              <span>众惠管理</span>
            </div>
            <div
              class="item"
              :class="index === 2 ? 'active' : ''"
              @click="index = 2"
            >
              <img
                v-if="index === 2"
                src="@/assets/images/myFavors/ct2y.png"
              >
              <img
                v-else
                src="@/assets/images/myFavors/ct2n.png"
              >
              <span>线上交易</span>
            </div>
            <div
              class="item"
              :class="index === 3 ? 'active' : ''"
              @click="index = 3"
            >
              <img
                v-if="index === 3"
                src="@/assets/images/myFavors/ct1y.png"
              >
              <img
                v-else
                src="@/assets/images/myFavors/ct1n.png"
              >
              <span>定制服务</span>
            </div>
          </div>
          <p>{{ aaArray[index-1] }}</p>
        </div>
      </div>
    </div>
    <div class="swiper-wrap">
      <swiper
        ref="mySwiper"
        class="us-swiper"
        :options="swiperOption"
      >
        <swiper-slide>
          <div class="swiper-item">
            <div class="left left1">
              <div class="quan">
                <em>{{ days[0].dd }}</em>
                <div class="cut" />
                <em>{{ days[0].mm }}</em>
              </div>
              <h3>产品及服务</h3>
              <h4>PRODUTS</h4>
              <p>AND SERVICES</p>
              <div class="cutl" />
              <h2>
                金融科技赋能<br>
                产品服务
              </h2>
            </div>
            <div class="right right1" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-item">
            <div class="left left2">
              <div class="quan">
                <em>{{ days[1].dd }}</em>
                <div class="cut" />
                <em>{{ days[1].mm }}</em>
              </div>
              <h3>基金超市</h3>
              <h4>FUND</h4>
              <p>SUPERMARKET</p>
              <div class="cutl" />
              <h2>
                金融产品销售<br>
                管理平台
              </h2>
            </div>
            <div class="right right2">
              <h3>公募基金产品</h3>
              <p>众惠基金与国内基金管理公司合作，代销其旗下公募基金产品，以基金超市的形式，帮助投资者进行一站式投资管理。众惠基金的公募基金产品包含货币型、债券型、混合型、权益型等多种类型，产品范围涵盖全市场，为投资者提供不同维度的优选基金，供投资者参考。</p>
              <h3>高端理财产品</h3>
              <p>众惠基金以客户长期收益获取为目标，利用完善的风控体系，独有的策略模型，为投资者甄选优质的高端理财产品。</p>
              <h3>券商资管、期货资管产品</h3>
              <p>众惠基金通过与券商、期货公司的合作，销售符合平台风控要求的资管产品。为客户提供更多的投资选择。</p>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-item">
            <div class="left left3">
              <div class="quan">
                <em>{{ days[2].dd }}</em>
                <div class="cut" />
                <em>{{ days[2].mm }}</em>
              </div>
              <h3>机构通/机构投资者</h3>
              <h4>INSTITUTIONAL</h4>
              <p>INVESTOR</p>
              <div class="cutl" />
              <h2>
                一站式场外<br>
                基金投资平台
              </h2>
            </div>
            <div class="right right3">
              <h3>机构通</h3>
              <h4>是众惠基金为机构投资者提供的一站式场外基金交易平台。</h4>
              <p>机构通将传统的现场柜台开户、交易转移到了线上，创建了便捷的机构开户、交易等流程。在此基础上，增加了资产划分、投前、投中、投后的一些专业管理功能。为机构投资者提供更加专业细分的基金销售服务。</p>
              <h3>机构通产品优势</h3>
              <h4>解决传统交易模式痛点</h4>
              <p>众惠基金机构通解决传统交易模式中，机构投资者需为配置公募基金建立运营团队和系统，且需要对接每一家公募基金管理公司，导致投资管理困难化等痛点问题。</p>
              <h4>降低运营成本</h4>
              <p>众惠基金机构通降低了机构投资者配置运营人员以及系统建设的成本，同时降低了多头对接的沟通成本。</p>
              <h4>提高交易效率</h4>
              <p>
                通过众惠基金机构通产品，机构投资者可直接购买绝大部分公募基金，提高了投资交易效率。
              </p>
              <h4>规范交易流程</h4>
              <p>
                通过众惠基金机构通系统，机构投资者可以通过规范交易流程，严格管控业务操作风险，便于投前、投中、投后管理。
              </p>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-item">
            <div class="left left4">
              <div class="quan">
                <em>{{ days[3].dd }}</em>
                <div class="cut" />
                <em>{{ days[3].mm }}</em>
              </div>
              <h3>资产配置服务</h3>
              <h4>ASSET</h4>
              <p>ALLOCATION SERVICE</p>
              <div class="cutl" />
            </div>
            <div class="right right4">
              <h3>资产配置</h3>
              <h4>众惠基金坚持从客户需求本身出发</h4>
              <p>依托众惠基金销售综合管理平台持续引入优质的投资产品，采用从宏观策略到金融工具模型的自上而下全方位研究方法，为高净值客户群体提供定制化资产配置方案及风险控制方案。通过持续了解客户的需求及市场的变化，客观的为客户匹配合适的解决方案，帮助客户实现中长期的财富保值增值。</p>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-item">
            <div class="left left5">
              <div class="quan">
                <em>{{ days[4].dd }}</em>
                <div class="cut" />
                <em>{{ days[4].mm }}</em>
              </div>
              <h3>投资者教育</h3>
              <h4>PRE-DEAL</h4>
              <p>INVESTOR EDUCATION</p>
              <div class="cutl" />
            </div>
            <div class="right right5">
              <h3>教育服务</h3>
              <p>众惠基金综合销售管理平台投资人端针对不同阶段的投资者教育，专门设立了众惠学堂模块，通过通俗风趣的方式培养不同阶段投资者的财富管理理念。为投资者提供专业易懂的养“基”攻略及财经资讯。</p>
            </div>
          </div>
        </swiper-slide>
        <div
          slot="pagination"
          class="swiper-pagination"
        />
        <!-- <div
          slot="button-prev"
          class="iconfont icon-mine-up2"
        />
        <div
          slot="button-next"
          class="iconfont icon-mine-down2"
        /> -->
      </swiper>
    </div>
    
    <div class="flex-center">
      <img src="@/assets/images/myFavors/cc5.png">
      <div class="txt-t">
        <span>COOPERATIVE ENTERPRISE</span>
        <h3>部分合作伙伴</h3>
        <p>合作创造价值 互利成就未来</p>
      </div>
      <div class="partner">
        <div
          v-for="item in 33"
          :key="item"
        >
          <img :src="require('@/static/img/aboutus/uniorg/' + item + '.jpg')">
        </div>
      </div>
      <p>*此处为众惠基金部分合作伙伴，合作伙伴顺序不分先后</p>
    </div>
  </div>
</template>

<script>
  // 引入插件
  import { swiper, swiperSlide } from "vue-awesome-swiper";
  import "swiper/dist/css/swiper.css";
  const FIVE_DAYS = []
  for (let i=0; i<5;) {
    const dd = ++i
    const mm = 5

    FIVE_DAYS.push({
      dd: ('' + dd).length < 2 ? '0' + dd : dd,
      mm: ('' + mm).length < 2 ? '0' + mm : mm
    })
  }
  console.log(FIVE_DAYS)
  export default {
    components: {
      swiper,
      swiperSlide
    },
    data() {
      return {
        days: FIVE_DAYS,
        index: 1,
        swIdx: 0,
        aaArray:['众惠基金的管理层及核心团队由来自银行、证券机构、基金、互联网金融等金融服务领域的行业精英和互联网专家组成，在基金研究、风险控制、销售服务、金融科技、渠道建设等方面均有着丰富的从业经验。', '众惠基金利用信息化系统的科技投入，打通了客户、产品、服务和流程等全部环节，并为众惠基金数字化赋能。打造“场景+数据+算法”为核心的营销服务体系，转换新的营销思维模式，积极探索数据带来的价值', '众惠基金运用数字化技术、定制流程化的运营管理，将传统的被动式服务转变为主动的MOT服务。利用关键时刻的销售协同，持续关注投资人的风险偏好、投资经历、投资目标等需求变化，客观为投资人提出匹配的解决方案及专属投资顾问式服务。'],
        swiperOption: {
          loop: true,
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false
          },
          direction: 'vertical',
          // 显示分页
          pagination: {
            el: ".swiper-pagination",
            clickable: true //允许分页点击跳转
          },
          // // 设置点击箭头
          // navigation: {
          //   nextEl: ".icon-mine-down2",
          //   prevEl: ".icon-mine-up2"
          // }
        }
      }
    },
    computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {
    // current swiper instance
    console.log("this is current swiper instance object", this.swiper);
    // this.swiper.slideTo(3, 1000, false);
  }
  }
</script>

<style lang="less" scoped>
@import url('@/assets/css/zc-common.less');
.swiper-wrap {
  margin-top: 31px;
  width: 100%;
  height: 800px;
  .us-swiper {
    width: 100%;
    height: 800px;
    .swiper-item {
      width: 100%;
      height: 100%;
      display: flex;
      overflow: hidden;
      .left {
        width: 500px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        .quan {
          margin-top: 60px;
          width: 152px;
          height: 152px;
          border: 4px solid #FFFFFF;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .cut {
            height: 2px;
            background: #FFFFFF;
            width: 60px;
            margin-top: 12px;
            margin-bottom: 12px;
          }
          em {
            font-size: 36px;
            font-style: normal;
            color: #FFFFFF;
          }
        }
        &>h3 {
          font-weight: bold;
          font-size: 40px;
          color: #FFFFFF;
          margin-top: 68px;
        }
        &>h4 {
          font-weight: bold;
          font-size: 54px;
          color: #F6F5F3;
          margin-top: 34px;
        }
        &>p {
          font-weight: normal;
          font-size: 36px;
          color: #F6F5F3;
          margin-top: 6px;
        }
        &>.cutl {
          width: 336px;
          height: 1px;
          background: #fff;
          margin-top: 44px;
        }
        &>h2 {
          font-weight: bold;
          font-size: 40px;
          margin-top: 56px;
          text-align: center;
          color: #FFFFFF;
        }
        &.left1 {
          background: #E2A15A;
        }
        &.left2 {
          background: #D06746;
        }
        &.left3 {
          background: #60A782;
        }
        &.left4 {
          background: #5D7CD3;
        }
        &.left5 {
          background: #6549D9;
        }
      }
      .right {
        flex: 1;
        height: 100%;
        &.right1 {
          background: url('../../assets/images/myFavors/sw1.png');
          background-size: 100% 100%;
        }
        &.right2 {
          background: url('../../assets/images/myFavors/sw2.png');
          background-size: 100% 100%;
          padding-left: 70px;
          padding-top: 40px;
          padding-right: 210px;
          &>h3 {
            margin-top: 24px;
            width: 100%;
            padding-left: 40px;
            font-weight: bold;
            font-size: 26px;
            color: #D06746;
            position: relative;
            &::before {
              content: '';
              display: inline-block;
              width: 14px;
              height: 14px;
              background: #D06746;
              transform: rotate(45deg);
              position: absolute;
              left: 0;
              top: 12px;
            }
          }
          &> p {
            margin-top: 12px;
            padding-left: 40px;
            font-size: 14px;
            color: #D06746;
          }
        }
        &.right3 {
          background: url('../../assets/images/myFavors/sw3.png');
          background-size: 100% 100%;
          padding-top: 40px;
          padding-left: 76px;
          padding-right: 344px;
          &>h3 {
            margin-top: 24px;
            font-weight: bold;
            font-size: 36px;
            color: #60A782;
          }
          &>h4 {
            margin-top: 24px;
            font-weight: bold;
            font-size: 24px;
            color: #60A782;
          }
          &>p {
            margin-top: 10px;
            font-size: 14px;
            color: #60A782;
          }
        }
        &.right4 {
          background: url('../../assets/images/myFavors/sw4.png');
          background-size: 100% 100%;
          padding-top: 40px;
          padding-left: 76px;
          padding-right: 344px;
          &>h3 {
            margin-top: 24px;
            font-weight: bold;
            font-size: 36px;
            color: #5D7CD3;
          }
          &>h4 {
            margin-top: 24px;
            font-weight: bold;
            font-size: 24px;
            color: #5D7CD3;
          }
          &>p {
            margin-top: 10px;
            font-size: 14px;
            color: #5D7CD3;
          }
        }
        &.right5 {
          background: url('../../assets/images/myFavors/sw5.png');
          background-size: 100% 100%;
          padding-top: 40px;
          padding-left: 76px;
          padding-right: 344px;
          &>h3 {
            margin-top: 24px;
            font-weight: bold;
            font-size: 36px;
            color: #5737DD;
          }
          &>p {
            margin-top: 10px;
            font-size: 14px;
            color: #5737DD;
          }
        }
      }
    }
  }
}
// .parallax-bg {
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 130%;
//     height: 100%;
//     background-size: cover;
//     background-position: left;
//     background-image: url('/images/example/1.jpg');
//   }

// .swiper {
//   width: 100%;
//   height: 800px;

//   .swiper-slide {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     color: #fff;
//     box-sizing: border-box;
//     padding: 0 80px;
//     background-color: transparent;

//     .title {
//       margin-bottom: 12px;
//       font-size: 24px;
//       font-weight: bold;
//     }

//     .subtitle {
//       margin-bottom: 12px;
//       font-size:  24px;
//     }

//     .text {
//       max-width: 430px;
//       line-height: 1.32;
//     }
//   }
// }


.rr-wrap {
  width: 100%;
  height: 800px;
  display: flex;
  overflow: hidden;
  .left {
    height: 100%;
    flex: 1;
  }
  .right{
    height: 100%;
    flex: 1;
  }
}


.aa-wrap {
  margin-top: 30px;
  width: 100%;
  
  &>h3 {
    width: 100%;
    font-weight: normal;
    font-size: 36px;
    color: #1F1F1F;
    text-align: center;
  }
  .rect-w {
    width: 100%;
    height: 378px;
    display: flex;
    margin-top: 70px;
    &>img {
      width: 650px;
      height: 100%;
    }
    &>div {
      flex: 1;
      height: 100%;
      background: #F3F4F4;
      padding: 53px 70px;
      p {
        font-size: 16px;
        color: #6B6B6B;
        margin-top: 56px;
      }
      .top {

        height: 125px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &>.item {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          &>img {
            width: 72px;
            height: 72px;
          }
          &>span {
            margin-top: 8px;
            font-size: 20px;
            color: #25293D;
          }
          &.active {
            border-bottom: 2px solid #CE9B63;
            i {
              color: #25293D;
            }
            &>span {
              color: #25293D;
            }
          }
        }
      }
    }
  }
}
.cul-wrap {
  margin-top: 97px;
  width: 100%;
  height: 800px;
  overflow: hidden;
  background: url('../../assets/images/myFavors/cpic.jpg') no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  position: relative;
  .txt-t {
    width: 100%;
    margin-top: 10px;
    position: absolute;
    top: 52px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    &>p {
      font-size: 24px;
      color: #fff;
      text-align: center;
    }
    &>h3 {
      width: 406px;
      font-weight: bold;
      font-size: 45px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -8px;
      left: calc(50% - 203px);
      &::before {
        content: '';
        display: inline-block;
        height: 6px;
        width: 60px;
        background: #fff;
        margin-right: 8px;
        opacity: 0.8;
      }
      &::after {
        content: '';
        display: inline-block;
        height: 6px;
        width: 60px;
        background: #fff;
        margin-left: 8px;
        opacity: 0.8;
      }
    }
    &>span {
      font-weight: bold;
      font-size: 60px;
      background-image: linear-gradient(to bottom, rgba(255,255,255,0.2), rgba(255,255,255, 0.028));
      background-clip: text;
      color: transparent;
    }
  }
  .cul-item {
    flex: 1;
    height: 100%;
    position: relative;
    &>h3 {
      width: 100%;
      font-weight: bold;
      font-size: 36px;
      color: #FFFFFF;
      text-align: center;
      position: absolute;
      top: 466px;
      left: 0;
      transition: all 500ms linear;
    }
    &>p {
      width: 100%;
      position: absolute;
      top: 526px;
      left: 0;
      font-size: 24px;
      color: #FFFFFF;
      text-align: center;
      transition: all 500ms linear;
    }
    &>em {
      font-style: normal;
      display: inline-block;
      width: 100%;
      padding: 0 40px;
      font-size: 32px;
      text-align: center;
      color: #fff;
      position: absolute;
      top: 100%;
      transition: all 500ms linear;
    }
    &:hover {
      // background: url('../../assets/images/myFavors/culFbg1.png') no-repeat center center;
      
      &.item1 {
        background: url('../../assets/images/myFavors/culFbg1.png') no-repeat center center;
        background-size: 100% 100%;
      }
      &.item2 {
        background: url('../../assets/images/myFavors/culFbg2.png') no-repeat center center;
        background-size: 100% 100%;
      }
      &.item3 {
        background: url('../../assets/images/myFavors/culFbg3.png') no-repeat center center;
        background-size: 100% 100%;
      }
      &.item4 {
        background: url('../../assets/images/myFavors/culFbg4.png') no-repeat center center;
        background-size: 100% 100%;
      }
      &>h3 {
        top: 306px;
      }
      &>p {
        top: 366px;
      }
      &>em {
        top: 466px;
      }
    }
  }
}

.about-wrap {
  width: 100%;
  padding-bottom: 28px;
  &>.img-wrap {
    width: 100%;
    height: 480px;
    background: url('../../assets/images/myFavors/aboutbg.jpg') no-repeat center center;
    background-size: 100% 100%;
    .txt-w {
      width: 1200px;
      margin: 0 auto;
      height: 100%;
      padding-top: 143px;
      p {
        color: #FFFFFF;
        &:nth-child(1) {
          font-size: 44.8018px;
        }
        &:nth-child(2) {
          font-size: 47.0419px;
          margin-top: 18px;
        }
        &:nth-child(3) {
          font-size: 24px;
          margin-top: 23px;
          opacity: 0.8;
        }
      }
    }
  }
}
.ct-x {
  width: 100%;
  .ct-a {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    position: relative;
    &>img {
      height: 385px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    .ctx-b {
      width: 540px;
      padding-top: 40px;
      
      &>div {
        color: #CE9B63;
        position: relative;
        opacity: 0.8;
        h3 {
          font-weight: bold;
          font-size: 48px;
        }
        p {
          font-size: 24px;
        }
        span {
          font-weight: bold;
          font-size: 72px;
          color: #F6F5F3;
          position: absolute;
          left: 0;
          top: -30px;
          z-index: -1;
        }
      }
      &>p {
        margin-top: 24px;
        font-size: 16px;
        color: #6B6B6B;
        line-height: 32px;
      }
    }
    
  }
}
.ct-w {
  margin-top: 86px;
  width: 100%;
  .ct-a{
    width: 1200px;
    margin: 0 auto;
    &>h3 {
      font-weight: bold;
      font-size: 48px;
      color: #CE9B63;
      text-align: center;
    }
    p {
      margin-top: 24px;
      font-size: 16px;
      line-height: 32px;
      color: #6B6B6B;
    }
    .img-wrap1 {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &>img {
        width: 380px;
        height: 250px;
        margin-top: 30px;
      }
    }
  }
}


.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 70px;
  &>p  {
    margin-top: 28px;
    font-size: 16px;
    color: #DEB87B;
  }
  &>img {
    width: 112px;
    height: 112px;
  }
  .txt-t {
    margin-top: 10px;
    position: relative;
    &>p {
      font-size: 24px;
      color: #DEB87B;
      text-align: center;
    }
    &>h3 {
      width: 406px;
      font-weight: bold;
      font-size: 45px;
      color: #DEB87B;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -8px;
      left: calc(50% - 203px);
      &::before {
        content: '';
        display: inline-block;
        height: 6px;
        width: 60px;
        background: #DEB87B;
        margin-right: 8px;
        opacity: 0.8;
      }
      &::after {
        content: '';
        display: inline-block;
        height: 6px;
        width: 60px;
        background: #DEB87B;
        margin-left: 8px;
        opacity: 0.8;
      }
    }
    &>span {
      font-weight: bold;
      font-size: 60px;
      background-image: linear-gradient(to bottom, rgba(255,245,234,0.2), rgba(222,184,123, 0.2));
      background-clip: text;
      color: transparent;
    }
  }
  .partner {
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin-top: 58px;
    &>div {
      width: 212px;
      height: 104px;
      border: 1px solid #EDEDED;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
      margin-right: 34px;
      &:last-child{
        margin-right: 34px;
      }
      &:nth-child(5n){
        margin-right: 0px;
        
      }
      &>img {
        width: 192px;
        height: 100px;
      }
    }
  }
}

</style>